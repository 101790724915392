'use client'

import ErrorPageContent, { ErrorPageProps } from './error'

export default function GlobalError(props: ErrorPageProps) {
  return (
    <html>
      <body>
        <ErrorPageContent {...props} />
      </body>
    </html>
  )
}
